import {AbiItem} from 'web3-utils';
import {ETH_NETWORK} from '@/utils/contracts/network';

const getReplicantXStakingContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_REPLICANTX_STAKING_CONTRACT_ADDRESS;

  if (!address) {
    throw new Error(
      'NEXT_PUBLIC_REPLICANTX_STAKING_CONTRACT_ADDRESS env variable is required!'
    );
  }

  return address;
};

const getReplicantXContractAbi = (network: string) => {
  // Need to do this because we don't want to re-deploy the actual replicantX contract on goerli, just need the ABI
  switch (network) {
    case 'sepolia':
      return require('../../dominionx/mockReplicantXAbi.json')
        .abi as Array<AbiItem>;
    case 'goerli':
      return require('../../dominionx/mockReplicantXAbi.json')
        .abi as Array<AbiItem>;
    case 'mainnet':
      return require('../../dominionx/replicantxAbi.json')
        .abi as Array<AbiItem>;
    case 'ropsten':
    case 'hardhat':
    case 'localhost':
      throw new Error(`Address not available for network: ${network}`);
    default:
      throw new Error(`Address not available for network: ${network}`);
  }
};

const getReplicantXStakingContractAbi = () => {
  const metadata = require('../../../generated/contracts/ReplicantXStaking.json');

  return metadata.abi as Array<AbiItem>;
};

const getReplicantXContractAddress = (network: string): string => {
  const address = process.env.NEXT_PUBLIC_REPLICANTX_CONTRACT_ADDRESS;

  if (!address) {
    throw new Error(
      'NEXT_PUBLIC_REPLICANTX_CONTRACT_ADDRESS env variable is required!'
    );
  }

  return address;
};

export const REPLICANTX_CONTRACT_ADDRESS =
  getReplicantXContractAddress(ETH_NETWORK);
export const REPLICANTX_STAKING_CONTRACT_ADDRESS =
  getReplicantXStakingContractAddress(ETH_NETWORK);

export const REPLICANTX_CONTRACT_ABI = getReplicantXContractAbi(ETH_NETWORK);
export const REPLICANTX_STAKING_CONTRACT_ABI =
  getReplicantXStakingContractAbi();
